import * as React from "react";
import { Col, Row } from "react-grid-system";
import HcpLayout from "../components/hcp/layout";
import { Seo } from "../components/seo";
import "./contact-us.scss";
import harmonyLogo from "../images/HarmonyLogo_primary_NO-LLC_RGB.svg";
import wakixForYouLogo from "../images/wak_for_you.4233dd7a.svg";

const ContactUs = () => {
  return (
    <HcpLayout pageClass="contact-us" noStickyHCPLocatorCTA={true}>
      <section className="contact-us__info inner-first">
        <div className="centered-content">
          <h1>Contact Us</h1>
          <div className="content-block">
            <Row className="row  no-border">
              <Col lg={3}>
                <div className="logo">
                  <img
                    src={harmonyLogo}
                    alt="Harmony Biosciences logo"
                    className="harmony-logo"
                  />
                </div>
              </Col>
              <Col lg={9}>
                <div className="desc">
                  For product information, contact Harmony Biosciences Medical
                  Information department:
                  <ul className="blueDot-list pl15">
                    <li className="pb-5">
                      <a href="tel:18008337460 ">1-800-833-7460 </a>
                    </li>
                    <li>
                      <a
                        href="mailto:medinfo@harmonybiosciences.com"
                        className="link-blue"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        medinfo@harmonybiosciences.com
                      </a>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
            <Row className="row">
              <Col lg={3}>
                <div className="logo">
                  <img
                    src={wakixForYouLogo}
                    className="w4u-logo"
                    alt="WAKIX for You logo"
                  />
                </div>
              </Col>
              <Col lg={9}>
                <div className="desc">
                  For information about the{" "}
                  <span className="italic">WAKIX for You</span> patient support
                  program and services available:
                  <ul className="blueDot-list pl15">
                    <li>
                      <a href="tel:18559254948">
                        1-855-WAKIX4U (1-855-925-4948){" "}
                      </a>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </section>
    </HcpLayout>
  );
};

export default ContactUs;

export const Head = () => (
  <Seo
    title="Contact Us | WAKIX® (pitolisant)"
    description="View our contact information here."
  />
);
